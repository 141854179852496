import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

/** DHBW-Colors:
 * Grey: #5C6971
 * Red: #E2001A
 *
 */

const options = {
  theme: {
    dark: true,
    default: 'dark',
    lighten: 1,
    darken: 2,
    themes: {
      light: {
        primary: '#E2001A',
        secondary: '#5C6971',
        accent: '#52000c',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      },
      dark: {
        primary: '#E2001A',
        secondary: '#BDBDBD',
        accent: '#52000c',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    }
  }
}

export default new Vuetify({ ...options })
