import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLuxon from 'vue-luxon'
import { rules } from './globals'
import { createProvider } from './vue-apollo'
import './registerServiceWorker'

Vue.use(VueLuxon, { output: { format: 'relative', locale: 'en', relative: { style: 'short' } } })

Vue.config.productionTip = true

Vue.prototype.$rules = rules

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider({
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    query: {
      fetchPolicy: 'cache-and-network'
    }
  }),
  render: (h) => h(App)
}).$mount('#app')
