<template>
  <v-dialog v-model="updateExists" max-width="500" persistent>
    <v-card>
      <v-card-title> New Update available! </v-card-title>

      <v-card-text>
        A new version of the CEK is available. You can update later or reaload the page to use the new version.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="updateExists = false"> No </v-btn>
        <v-btn text color="error" @click="updateNow"> Yes, update! </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppUpdate',
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  mounted() {
    document.addEventListener('swUpdated', this.showModal, { once: true })

    if (!navigator.serviceWorker) return

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  methods: {
    showModal(e) {
      this.registration = e.detail
      this.updateExists = true
    },
    updateNow() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage('skipWaiting')
    }
  }
}
</script>
