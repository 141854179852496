import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showErrorDialog: false,
    customErrorMessage: 'lololo'
  },
  mutations: {
    openErrorDialog(state, customMessage) {
      state.showErrorDialog = true
      state.customErrorMessage = customMessage
    },
    closeErrorDialog(state) {
      state.showErrorDialog = false
    }
  },
  actions: {},
  modules: {}
})
