<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <div class="text-h1">Welcome to the CEK!</div>
        <div class="text-h6">DHBW Engineering Car Evaluation Kit</div>
      </v-col>
      <v-col>
        <v-card to="/data" max-width="400">
          <v-img src="@assets/team-it-logo.jpg" alt="Data tables" height="200" />
          <v-card-title>Data</v-card-title>
          <v-card-subtitle>Search for data you need! Full text search is coming soon!</v-card-subtitle>
        </v-card>
      </v-col>
      <v-col>
        <v-card to="/setup" max-width="400">
          <v-img
            :src="
              $vuetify.theme.dark
                ? require('@assets/dallara-gp208_white.png')
                : require('@assets/dallara-gp208_black.png')
            "
            alt="Setup-Picture"
            height="200"
          />
          <v-card-title>Setup</v-card-title>
          <v-card-subtitle>View Setups, Setup Helper Tools...</v-card-subtitle>
        </v-card>
      </v-col>
      <v-col>
        <v-card to="/testing" max-width="400">
          <v-img src="@assets/sleek21.jpg" alt="Setup-Picture" height="200" />
          <v-card-title>Testing</v-card-title>
          <v-card-subtitle>Make records for Testing Days</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>
