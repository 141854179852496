<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-title> Whoopsie! </v-card-title>

      <v-card-text>Something went wrong! Ensure you are connected to the internet and try again!</v-card-text>
      <v-card-text>{{ $store.state.customErrorMessage }}</v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppErrorDialog',
  props: { show: { type: Boolean, required: true } }
}
</script>
