import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/setup',
    name: 'Setups',
    component: () => import(/* webpackChunkName: "setups" */ '@/views/Setups.vue')
  },
  {
    path: '/cars',
    name: 'Cars',
    component: () => import(/* webpackChunkName: "cars" */ '@/views/Cars.vue')
  },
  {
    path: '/setup/:id',
    name: 'Setup',
    component: () => import(/* webpackChunkName: "setup" */ '@/views/Setup.vue')
  },
  {
    path: '/setup/flow/:id',
    name: 'SetupFlow',
    component: () => import(/* webpackChunkName: "setupflow" */ '@/views/SetupFlow.vue')
  },
  {
    path: '/manoeuvres',
    name: 'Manoeuvres',
    component: () => import(/* webpackChunkName: "manoeuvres" */ '@/views/Manoeuvres.vue')
  },
  {
    path: '/testing',
    name: 'Testing',
    component: () => import(/* webpackChunkNam: "testing" */ '@/views/Testing.vue')
  },
  {
    path: '/testing/:date',
    name: 'TestingDay',
    component: () => import(/* webpackChunkName: "testingDay" */ '../views/TestingDay.vue')
  },
  {
    path: '/testing/run/:id',
    name: 'TestingRun',
    component: () => import(/* webpackChunkName: "testingRun" */ '../views/TestingRun.vue')
  },
  {
    path: '/data',
    name: 'Data',
    component: () => import(/* webpackChunkName: "data" */ '../views/Data.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
