<template>
  <v-app>
    <the-sidebar :user="loggedUser" :authenticated="authenticated" @connectNextcloud="connectNextcloud" />
    <the-update-dialog />

    <v-container class="d-flex align-center justify-center fill-height" v-if="fetching">
      <v-progress-circular indeterminate color="primary" :size="100"></v-progress-circular>
    </v-container>

    <v-main v-if="authenticated">
      <router-view />
    </v-main>

    <app-error-dialog :show="$store.state.showErrorDialog" @close="$store.commit('closeErrorDialog')" />
  </v-app>
</template>

<script>
import { UserInfo } from '@graphql/Users.gql'

import AppErrorDialog from '@components/app/ErrorDialog.vue'
import TheSidebar from '@components/TheSidebar.vue'
import TheUpdateDialog from '@/components/TheUpdateDialog.vue'

export default {
  components: { AppErrorDialog, TheSidebar, TheUpdateDialog },
  name: 'App',
  apollo: {
    loggedUser: { query: UserInfo }
  },
  data: () => ({
    fetching: true,
    authenticated: false,
    loggedUser: {}
  }),
  mounted() {
    this.setDefaultTheme()
    // TODO: Catch errors
    const url = `/api/auth`
    fetch(url).then(
      ((res) => {
        this.fetching = false
        if (res.status === 200) this.authenticated = true
        else window.location.replace(`/api/auth/login?originalUrl=${window.location.pathname}&social=google`)
      }).bind(this)
    )
  },
  methods: {
    connectNextcloud() {
      // TODO: Catch errors
      window.location.replace(`/api/auth/login?originalUrl=${window.location.pathname}&social=nextcloud`)
    },
    setDefaultTheme() {
      if (!window.matchMedia) return

      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        this.$vuetify.theme.dark = false
      } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$vuetify.theme.dark = true
      }

      window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', (e) => {
        if (e.matches) {
          this.$vuetify.theme.dark = false
        } else {
          this.$vuetify.theme.dark = true
        }
      })
    }
  }
}
</script>

<style>
.theme--light .scrollable::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.theme--light .scrollable::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 7px;
}

.theme--light .scrollable::-webkit-scrollbar-thumb:hover {
  background: black;
}

.theme--dark .scrollable::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.theme--dark .scrollable::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border-radius: 7px;
}

.theme--dark .scrollable::-webkit-scrollbar-thumb:hover {
  background: white;
}

.text-multiline {
  white-space: pre-wrap;
}
</style>
