var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"fixed":"","bottom":"","permanent":"","src":require("@/assets/navbar-background.jpg"),"expand-on-hover":"","app":"","dark":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("CEK")]),_c('v-list-item-subtitle',[_vm._v("Car Evaluation Kit")])],1)],1),(_vm.authenticated)?_c('div',[_c('v-divider'),_c('v-list',_vm._l((_vm.routes),function(ref,i){
var icon = ref[0];
var text = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"to":route,"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text)+" ")])],1)],1)}),1),(_vm.user.role !== 'USER')?_c('v-divider'):_vm._e(),(_vm.user.role !== 'USER')?_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Moderator Area")])],1)],1),_vm._l((_vm.moderatorRoutes),function(ref,i){
var icon = ref[0];
var text = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"to":route,"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text)+" ")])],1)],1)})],2):_vm._e(),_c('v-divider'),_c('v-list',[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.user.googlePicture}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.user.fullname)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.role))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1),_c('v-list-item-action',[_c('v-switch',{attrs:{"label":"Dark Theme","color":"primary","dense":"","inset":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',{attrs:{"href":"https://git.dhbw-engineering.de/_vdc/cek/-/issues/new","target":"blank","link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-alert-octagon")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Issues / Ideas")])],1)],1)],1),_c('v-divider')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }