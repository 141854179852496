/**
 * Use this file to register any variables or functions that should be available globally
 * ideally you should make it available via the window object
 * as well as the Vue prototype for access throughout the app
 * (register globals with care, only when it makes since to be accessible app wide)
 */

export const rules = {
  required: [(v) => !!v || 'Is required'],
  requiredMax20: [(v) => !!v || 'Is required', (v) => v.length <= 20 || 'Must be less than 20 characters'],
  year: [(v) => !!String(v).match(/\d{4}/i) || 'Must be a four-digit year'],
  requiredNumber: [(v) => (!isNaN(v) && !isNaN(parseFloat(v))) || 'Must be a number!'],
  number: [(v) => (v ? !isNaN(v) && !isNaN(parseFloat(v)) : true) || 'Must be a number!']
}
