<template>
  <v-navigation-drawer fixed bottom permanent src="~@/assets/navbar-background.jpg" expand-on-hover app dark>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">CEK</v-list-item-title>
        <v-list-item-subtitle>Car Evaluation Kit</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <div v-if="authenticated">
      <v-divider></v-divider>

      <v-list>
        <v-list-item v-for="([icon, text, route], i) in routes" :key="i" :to="route" link>
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider v-if="user.role !== 'USER'"></v-divider>

      <v-list v-if="user.role !== 'USER'">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Moderator Area</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="([icon, text, route], i) in moderatorRoutes" :key="i" :to="route" link>
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="user.googlePicture"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> {{ user.fullname }} </v-list-item-title>
            <v-list-item-subtitle>{{ user.role }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            <!-- <v-list-item-subtitle v-if="true /*user.connectedToNextcloud*/">
              <v-alert outlined dense type="info">Nextcloud connected</v-alert>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              <v-btn color="info" left @click="$emit('connectNextcloud')">Connect to Nextcloud</v-btn>
            </v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item-icon>

          <v-list-item-action>
            <v-switch v-model="$vuetify.theme.dark" label="Dark Theme" color="primary" dense inset />
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item href="https://git.dhbw-engineering.de/_vdc/cek/-/issues/new" target="blank" link>
          <v-list-item-icon>
            <v-icon>mdi-alert-octagon</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Issues / Ideas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'TheSidebar',
  props: {
    user: Object,
    authenticated: Boolean
  },
  data: () => ({
    routes: [
      ['mdi-home', 'Start', '/'],
      ['mdi-file-table-box-multiple', 'Data', '/data'],
      ['mdi-wrench', 'Setups', '/setup'],
      ['mdi-go-kart-track', 'Testing', '/testing']
    ],
    moderatorRoutes: [
      ['mdi-car', 'Cars', '/cars'],
      ['mdi-test-tube', 'Manoeuvres', '/manoeuvres'],
      ['mdi-account', 'User', '/user']
    ]
  })
}
</script>
